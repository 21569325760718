import { Input, Button } from '@electro/shared-ui-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

interface LoginData {
  email: string
  password: string
}

interface LoginProps {
  children: ReactNode | ReactNode[]
  onSubmit: (values: LoginData) => Promise<void> | void
  loading?: boolean
  email?: string
  password?: string
  error?: Partial<ApolloError>
  logo?: ReactNode
}

const loginValidationSchema = Yup.object().shape({
  password: Yup.string().required('login.validation.password'),
  email: Yup.string()
    .email('login.validation.enter_valid_email')
    .required('login.validation.email_required'),
})

const styles = {
  form: 'w-full',
  errorMessage: 'text-action-danger pt-0 pb-4',
  imageWrapper: 'justify-center flex mb-4 w-28 md:w-auto m-auto',
}

const LoginFormContext = createContext(null)

const LoginForm = ({
  children,
  email = '',
  password = '',
  loading,
  onSubmit,
  logo,
  error,
}: LoginProps) => {
  const formik = useFormik({
    initialValues: {
      password,
      email,
    },
    validationSchema: loginValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  const value = useMemo(
    () => ({
      formik,
      children,
      email,
      loading,
      onSubmit,
      logo,
      error,
      validateOnBlur,
    }),
    [formik, children, email, loading, onSubmit, logo, error, validateOnBlur],
  )

  return <LoginFormContext.Provider value={value}>{children}</LoginFormContext.Provider>
}

export const Fields = () => {
  const { t } = useTranslation('common')
  const { loading, formik, logo, error, validateOnBlur } = useContext(LoginFormContext)

  return (
    <form
      aria-label="login-form"
      id="login-form"
      onBlur={validateOnBlur}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form}>
        {!!logo && <div className={styles.imageWrapper}>{logo}</div>}
        <Input
          disabled={loading}
          fullWidth
          label={t('common.form.email_address')}
          placeholder={t('form_placeholder.email')}
          name="email"
          type="email"
          value={formik.values.email}
          errorMessage={t(formik.errors.email)}
          onChange={formik.handleChange}
        />
        <Input
          disabled={loading}
          fullWidth
          label={t('common.form.password')}
          placeholder="**************"
          name="password"
          type="password"
          password
          errorMessage={t(formik.errors.password)}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {!!error && (
          <div className={styles.errorMessage}>
            {error.message ? error.message : t('errors.something_went_wrong')}
          </div>
        )}
      </div>
    </form>
  )
}

const ForgottenPasswordButton = ({ onClick }) => {
  const { t } = useTranslation('common')
  return (
    <Button
      variant="naked"
      type="button"
      aria-label={t('common.form.forgotten_password')}
      className="text-white hover:text-tertiary mt-4 text-center text-sm block underline w-full"
      onClick={onClick}
    >
      {t('common.form.forgotten_password')}
    </Button>
  )
}

const SubmitButton = () => {
  const { t } = useTranslation('common')
  const { loading } = useContext(LoginFormContext)
  return (
    <Button
      form="login-form"
      id="login-button"
      data-testid="Submit"
      loading={loading}
      fullWidth
      type="submit"
    >
      {t('login.button.log_in')}
    </Button>
  )
}

LoginForm.ForgottenPasswordButton = ForgottenPasswordButton
LoginForm.Fields = Fields
LoginForm.SubmitButton = SubmitButton

export { LoginForm }
